export default [
    {
        path: '/dashboard',
        name: 'home',
        component: () => import('@/views/common/Home.vue'),
        meta: {
            pageTitle: 'Dashboard'
        },
    },
    {
        path: '/account',
        name: 'account',
        component: () => import('@/views/common/account/Account.vue'),
        meta: {
            pageTitle: 'My Account'
        },
    },
    {
        path: '/error-404',
        name: 'error-404',
        component: () => import('@/views/common/error/404.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            action: 'read',
        },
    },
    {
        path: '/error-not-authorized',
        name: 'error-not-authorized',
        component: () => import('@/views/common/error/NotAuthorized.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
        },
    },
]
