export default [
    {
        path: '/role/list',
        name: 'system-role-list',
        component: () => import('@/views/system/role/List.vue'),
        meta: {
            pageTitle: 'Role Management'
        },
    },
    {
        path: '/role/add/',
        name: 'system-role-add',
        component: () => import('@/views/system/role/Detail.vue'),
        meta: {
            pageTitle: 'Add Role',
            breadcrumb: [
                {
                    text: 'Role Management',
                    to: {name: 'system-role-list'}
                },
                {
                    text: 'Add',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/role/edit/:id',
        name: 'system-role-edit',
        component: () => import('@/views/system/role/Detail.vue'),
        meta: {
            pageTitle: 'Edit Role',
            breadcrumb: [
                {
                    text: 'Role Management',
                    to: {name: 'system-role-list'}
                },
                {
                    text: 'Edit',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/user/list',
        name: 'system-user-list',
        component: () => import('@/views/system/user/List.vue'),
        meta: {
            pageTitle: 'User Management'
        },
    },
    {
        path: '/user/add/',
        name: 'system-user-add',
        component: () => import('@/views/system/user/Detail.vue'),
        meta: {
            pageTitle: 'Add User',
            breadcrumb: [
                {
                    text: 'User Management',
                    to: {name: 'system-user-list'}
                },
                {
                    text: 'Add',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/user/edit/:id',
        name: 'system-user-edit',
        component: () => import('@/views/system/user/Detail.vue'),
        meta: {
            pageTitle: 'Edit User',
            breadcrumb: [
                {
                    text: 'User Management',
                    to: {name: 'system-user-list'}
                },
                {
                    text: 'Edit',
                    active: true,
                },
            ],
        },
    },
]
