export default [
    {
        path: '/member/list',
        name: 'member-list',
        component: () => import('@/views/member/List.vue'),
        meta: {
            pageTitle: 'Member Management'
        },
    },
    {
        path: '/member/add/',
        name: 'member-add',
        component: () => import('@/views/member/Detail.vue'),
        meta: {
            pageTitle: 'Add Member',
            breadcrumb: [
                {
                    text: 'Member Management',
                    to: {name: 'member-list'}
                },
                {
                    text: 'Add',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/member/edit/:id',
        name: 'member-edit',
        component: () => import('@/views/member/Detail.vue'),
        meta: {
            pageTitle: 'Edit Member',
            breadcrumb: [
                {
                    text: 'Member Management',
                    to: {name: 'member-list'}
                },
                {
                    text: 'Edit',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/member/payment-report',
        name: 'member-payment-report',
        component: () => import('@/views/report/List.vue'),
        meta: {
            pageTitle: 'Payment Report'
        },
    },
]
